import { getAxios, postAxios, putAxios, patchAxios } from "api/config";
import { AdminLoginInfo, KioskStatus, TgStatus } from "store/globalStateAtom";

/**로그인 */
export type LoginReqParams = {
  controlCode: string;
};
export type UserInfo = {
  kioskId: number;
  controlCode: string;
  promotionVideo: string | null;
  name: string;
};
export const login = async (payload: LoginReqParams) => {
  const res = await getAxios<UserInfo, LoginReqParams>(
    "/kiosk/sign-in",
    payload
  );
  return res.data;
};

/**환급 내역 조회 */
export type GetRefundInfoReqBody = {
  passportNumber: string;
  controlCode: string;
};
export type RefundInfo = {
  totalPrice: string;
  totalRefund: string;
  totalCount: string;
  refundList: Array<{
    franchiseeName: string;
    refundIndex: number;
    createdDate: string;
    price: string;
    refund: string;
  }>;
};
export const getRefundInfo = async (body: GetRefundInfoReqBody) => {
  const res = await postAxios<RefundInfo, GetRefundInfoReqBody, null>(
    "/kiosk/refunds",
    body
  );
  return res.data;
};

/**환급 완료(송금 상태 변경) */
export type CompleteRefundsReqBody = {
  controlCode: string;
  refundIndexList: Number[];
  bd1Count: number;
  bd2Count: number;
  hp1Count: number;
};
export const completeRefunds = async (body: CompleteRefundsReqBody) => {
  const res = await postAxios<number, CompleteRefundsReqBody, null>(
    "/kiosk/withdrawal",
    body
  );
  return res.status;
};

/** 보증금 내역 조회 */
export type GetDepositInfoReqBody = {
  passport: string;
};
export type DepositInfo = {
  response: {
    result_code: string;
    result_msg: string;
    amount: string;
  };
  notWithdrawn: {
    amount: number;
    theGreetRefundId: number[];
  };
};
export const getDepositInfo = async (body: GetDepositInfoReqBody) => {
  const res = await postAxios<DepositInfo, GetDepositInfoReqBody, null>(
    "/thegreet/lookup",
    body
  );
  return res.data;
};

/** 보증금 신청*/
export type PostDepositInfoReqBody = {
  passport: string;
  serial: string;
};
export type PostDepositInfo = {
  theGreetRefundId: number;
  response: {
    result_code: string;
    result_msg: string;
    amount: string;
  };
  notWithdrawn: {
    amount: number;
    theGreetRefundId: number;
  };
};
export const postDepositInfo = async (body: PostDepositInfoReqBody) => {
  const res = await postAxios<PostDepositInfo, PostDepositInfoReqBody, null>(
    "/thegreet/refund",
    body
  );
  return res.data;
};

/** 보증금 완료 업데이트 */
export type CompleteDepositReqBody = {
  controlCode: string;
  theGreetRefundIds: Number;
  bd1Count: number;
  bd2Count: number;
  hp1Count: number;
};

export const completeDeposit = async (body: CompleteDepositReqBody) => {
  const res = await patchAxios<number, CompleteDepositReqBody, null>(
    "/thegreet/refund",
    body
  );
  return res.status;
};

/** 키오스크 상태 업데이트*/
export type UpdateKioskStatusReqParams = {
  controlCode: string;
};
export type KiostStatusUpdateInfo = {
  type: "WITHDRAWAL" | "DEPOSIT";
  managerCode?: string;
};
export type UpdateKioskStatusReqPayload = UpdateKioskStatusReqParams &
  KioskStatus &
  KiostStatusUpdateInfo;
export const updateKioskStatus = async ({
  controlCode,
  ...body
}: UpdateKioskStatusReqPayload) => {
  const res = await putAxios<number, KioskStatus, null>(
    `/kiosk/status/${controlCode}`,
    body
  );
  return res.status;
};

/** 보증금 신청 미완료 업데이트 */
export type updateNotWithdrawnReqBody = {
  theGreetRefundId: number;
  notWithdrawCount: number;
};
export const updateNotWithdrawn = async (body: updateNotWithdrawnReqBody) => {
  const res = await postAxios<number, updateNotWithdrawnReqBody, null>(
    "/thegreet/refund/notwithdraw",
    body
  );
  return res.status;
};

/** 더그리트 상태 업데이트*/
export const updateTgStatus = async ({ ...body }: TgStatus) => {
  const res = await postAxios<number, TgStatus, null>(
    `/thegreet/status`,
    body
  );
  return res.status;
};

/** 키오스크 상태 조회*/
export const fetchKioskStatus = async (controlCode: string) => {
  const res = await getAxios<KioskStatus, string>(
    `/kiosk/status/${controlCode}`
  );
  return res.data;
};

export type CheckControlCodeReqBody = {
  controlCode: string;
};
/** 설정 페이지 로그인 */
export const checkControlCode = async (body: CheckControlCodeReqBody) => {
  const res = await postAxios<AdminLoginInfo, CheckControlCodeReqBody, null>(
    "/kiosk/setting",
    body
  );
  return res.data;
};

export type LoggingReqBody = {
  controlCode?: number;
  data: string;
};
/** 키오스크 통신 패킷 로깅 */
export const logging = async (body: LoggingReqBody) => {
  console.log(body.data); //디버깅용
  const res = await postAxios<number, LoggingReqBody, null>("/kiosk/log", body);
  return res.status;
};
