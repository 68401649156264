import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { getRefundInfo, GetRefundInfoReqBody, RefundInfo } from 'api';
import { Error } from 'api/config';
import { modalInfoAtom, refundInfoAtom } from 'store/globalStateAtom';
import { calculateCount } from 'utils';

function useGetRefundInfo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, setRefundInfo] = useAtom(refundInfoAtom);
  const [, setModalInfo] = useAtom(modalInfoAtom);

  return useMutation<RefundInfo, AxiosError<Error>, GetRefundInfoReqBody>(
    (payload) => getRefundInfo(payload),
    {
      retry: false,
      onSuccess: (data) => {
        setRefundInfo({
          refundIndexList: data.refundList.map((v) => v.refundIndex),
          ...calculateCount(data.totalRefund),
        });
        navigate('/refund', {
          state: {
            data,
          },
        });
      },
      onError: (err) => {
        const errCode = err.response?.data.code;
        switch (errCode) {
          //* 환급 가능 건이 없는 경우
          case 'R0001':
          case 'P0009':
            setModalInfo({
              title: t('error_passport_title'),
              description: t('error_passport_desc'),
              icon: 'ALERT',
            });
            break;
          case 'K0003':
            //* 재외국민 or 100만원 이상 결제건 밖에 없는 경우
            setModalInfo({
              title: t('error_refund_title'),
              description: t('error_refund_desc'),
              icon: 'ALERT',
            });
            break;
          default:
            if (err.message === 'Network Error') {
              setModalInfo({
                title: t('error_network_title'),
                description: t('error_network_desc'),
                icon: 'ALERT',
              });
            } else {
              setModalInfo({
                title: '에러가 발생했습니다.',
                description: t('error_default_desc'),
                icon: 'ERROR',
              });
            }
            break;
        }
      },
    }
  );
}

export default useGetRefundInfo;
