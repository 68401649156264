import { AxiosError } from "axios";
import { useAtom } from "jotai";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import { getDepositInfo, GetDepositInfoReqBody, DepositInfo } from "api";
import { Error } from "api/config";
import {
  modalInfoAtom,
  depositInfoAtom,
  emissionKioskStatusAtom,
} from "store/globalStateAtom";
import { depositCalculateCount } from "utils";

function useGetDepositInfo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, setDepositInfo] = useAtom(depositInfoAtom);
  const [, setModalInfo] = useAtom(modalInfoAtom);
  const [emissionKioskStatus, setEmissionKioskStatus] = useAtom(
    emissionKioskStatusAtom
  );

  return useMutation<DepositInfo, AxiosError<Error>, GetDepositInfoReqBody>(
    (payload) => getDepositInfo(payload),
    {
      retry: false,
      onSuccess: (data) => {
        // response에는 theGreetRefundId가 존재하지 않음.
        // 1. result_code === 000 일 경우 방출할 보증금이 존재함.
        // 2. result_code === 001 일 경우 방출할 보증금이 없음.
        // 3. result_code === 000 이면서 notWithdrawn === null 일 경우 data.response.amount만 방출
        // 4. result_code === 000 이면서 notWithdrawn !== null 일 경우 data.response.amount + data.notWithdrawn.amount 방출
        // 4. result_code === 001 이면서 notWithdrawn !== null 일 경우 data.notWithdrawn.amount만 방출
        console.log("조회시: ", emissionKioskStatus);
        if (data.response.result_code === "000" && data.notWithdrawn === null) {
          // 보증금만 존재할 떄
          setDepositInfo({
            ...depositCalculateCount(data.response.amount, emissionKioskStatus),
          });
        } else if (
          data.response.result_code === "000" &&
          data.notWithdrawn !== null
        ) {
          // 보증금이 있고 미방출금도 존재할 때
          setDepositInfo({
            theGreetRefundIds: data.notWithdrawn.theGreetRefundId,
            ...depositCalculateCount(
              parseInt(data.response.amount) + data.notWithdrawn.amount,
              emissionKioskStatus
            ),
          });
        } else if (
          data.response.result_code === "001" &&
          data.notWithdrawn !== null
        ) {
          console.log("조회: ", data.notWithdrawn.theGreetRefundId);
          // 보증금이 없고 미방출금이 존재할 때
          setDepositInfo({
            theGreetRefundIds: data.notWithdrawn.theGreetRefundId,
            ...depositCalculateCount(
              data.notWithdrawn.amount,
              emissionKioskStatus
            ),
          });
        }
        navigate("/deposit", {
          state: {
            data,
          },
        });
      },
      onError: (err) => {
        const errCode = err.response?.data.code;
        console.log("errCode", errCode);
        switch (errCode) {
          //* 환급 가능 건이 없는 경우
          case "TG0003":
          case "TG0005":
            setModalInfo({
              title: t("error_deposit_title"),
              description: t("error_passport_desc"),
              icon: "ALERT",
            });
            break;
          case "TG0004":
            setModalInfo({
              title: t("error_passport_title"),
              description: t("error_passport_desc"),
              icon: "ALERT",
            });
            break;
          default:
            if (err.message === "Network Error") {
              setModalInfo({
                title: t("error_network_title"),
                description: t("error_network_desc"),
                icon: "ALERT",
              });
            } else {
              setModalInfo({
                title: "에러가 발생했습니다.",
                description: t("error_default_desc"),
                icon: "ERROR",
              });
            }
            break;
        }
      },
    }
  );
}

export default useGetDepositInfo;
