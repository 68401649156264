import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import DepositSummaryTable from 'components/Deposit/DepositSummaryTable';
import Layout from 'components/common/Layout';
import progressKo from 'images/Refund/progress_ko_deposit_2.png';
import progressCh from 'images/Refund/progress_ch_deposit_2.png';
import progressEn from 'images/Refund/progress_en_deposit_2.png';
import progressJp from 'images/Refund/progress_jp_deposit_2.png';
import arrowLeft from 'images/common/chevron_left_black.png';
import withdraw from 'images/Refund/withdraw.png';
import {
  CountInfo,
  DepositCountInfo,
  languageAtom,
  emissionKioskStatusAtom,
  emissionSelectionAtom,
  loginInfoAtom,
  passportNumberAtom,
} from 'store/globalStateAtom';
import { comma, depositCalculateCount } from 'utils';
import useApplyDepositInfo from 'hooks/useApplyDepositInfo';
import { moneyNotWithdrawnAtom } from '../store/globalStateAtom';
import useButtonClicked from 'hooks/useButtonClicked';

type Props = {
  isLoading: boolean;
  depositEmitBills: (countInfo: DepositCountInfo) => void;
};
function Deposit({ isLoading, depositEmitBills }: Props) {
  const { t } = useTranslation();
  const locationState = useLocation().state;
  const data = locationState?.data;
  const [lang] = useAtom(languageAtom);
  const [emissionKioskStatus] = useAtom(emissionKioskStatusAtom);
  const [emissionInfo] = useAtom(emissionSelectionAtom);
  const [loginInfo] = useAtom(loginInfoAtom);
  const [passportInfo] = useAtom(passportNumberAtom);
  const { isButtonClicked, handleButtonClick } = useButtonClicked();
  // 보증금 신청
  const { mutate: applyDeposit } = useApplyDepositInfo({ depositEmitBills });

  const { response, notWithdrawn } = data;
  const navigate = useNavigate();
  const goBack = () => {
    navigate('/passport-certification');
  };

  const goNext = () => {
    if (isButtonClicked) return;
    handleButtonClick();
    if (data.response.result_code !== '000' && data.notWithdrawn) {
      // 추가 환급건이 존재하지 않고 미환급금만 존재할 경우
      depositEmitBills(
        depositCalculateCount(data.notWithdrawn.amount, emissionKioskStatus)
      );
    } else {
      // 기본 환급건이 존재할경우
      // 추가 환급건과 미환급금이 모두 존재할 경우
      applyDeposit({
        passport: passportInfo,
        serial: loginInfo.controlCode,
      });
    }
  };

  const getDepositText = (amount: number, lang: string) => {
    switch (lang) {
      case 'ko':
        return `${comma(amount)}원 보증금 받기`;
      case 'jp':
        return `KRW ${comma(amount)} デポジットの受け取り`;
      case 'ch':
        return `${comma(amount)} KRW 获取押金`;
      default:
        return `${comma(amount)} KRW Get Deposit`;
    }
  };

  return (
    <Layout isSetting>
      <img
        src={
          lang === 'en'
            ? progressEn
            : lang === 'jp'
            ? progressJp
            : lang === 'ch'
            ? progressCh
            : progressKo
        }
        height={90}
        width={960}
        alt="progress"
        className="mb-16 w-full"
      />
      <h2 className="text-head-01 font-bold text-mono-80 mt-16 mb-12">
        {t('collect_deposit')}
      </h2>
      {response && (
        <DepositSummaryTable
          amount={response?.amount || notWithdrawn?.amount}
        />
      )}
      <div className="flex justify-between">
        <button
          onClick={goBack}
          className="text-subHead-01 flex items-center bg-white py-6 px-12 rounded-100 text-mono-80 font-semibold mt-36 shadow-default"
        >
          <img src={arrowLeft} width={36} height={36} alt="arrow_left" />
          {t('prev_step')}
        </button>
        <button
          onClick={goNext}
          className="text-head-02 flex items-center disabled:bg-blue-20 bg-blue-100 text-white py-6 px-12 rounded-100 font-semibold mt-36 shadow-default active:bg-blue-150"
        >
          <img
            src={withdraw}
            width={40}
            height={40}
            alt="withdraw"
            className="mr-2"
          />
          {response.amount && <>{getDepositText(response?.amount, lang)}</>}
          {notWithdrawn && <>{getDepositText(notWithdrawn?.amount, lang)}</>}
        </button>
      </div>
    </Layout>
  );
}

export default Deposit;
