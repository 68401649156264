import Layout from 'components/common/Layout';
import React from 'react';
import logo from 'images/common/ktp_logo_blue.png';
import BtnItem from 'components/EmissionSelection/BtnItem';
import refundIcon from 'images/common/refund.png';
import depositIcon from 'images/common/tg_deposit.png';

function EmissionSelection() {
  return (
    <Layout isKtpLogo={false}>
      <div className='flex justify-start'>
        <img src={logo} height={78} width={316} alt='ktp_logo' />
      </div>
      <div className='flex flex-col gap-14 justify-between mt-[160px]'>
        <BtnItem imgUrl={refundIcon} isRefund />
        <BtnItem imgUrl={depositIcon} />
      </div>
    </Layout>
  );
}

export default EmissionSelection;
