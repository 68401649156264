import React, { useRef, useState } from 'react';

import LoadingView from 'components/common/LoadingView';
import QwertyKeypad from 'components/common/QwertyKeypad';
import useLogin from 'hooks/useLogin';
import logo from 'images/common/ktp_logo_blue.png';

function Login() {
  const [controlCode, setControlCode] = useState('');
  const { mutate, isLoading } = useLogin();

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setControlCode(e.target.value.trim().toUpperCase());
  };

  const goNext = () => {
    mutate({ controlCode });
  };

  const handleContextMenu: React.MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
  };

  return (
    <main
      onContextMenu={handleContextMenu}
      className="w-full min-h-[1920px] bg-mono-10 p-14 pt-80 overflow-hidden"
    >
      {isLoading && <LoadingView />}
      <img
        src={logo}
        height={78}
        width={316}
        alt="ktp_logo"
        className="mx-auto"
      />
      <h3 className="text-head-01 font-bold text-center mt-48 mb-20">
        키오스크 사용을 위해
        <br />
        시리얼 넘버를 입력해주세요.
      </h3>
      <input
        className="py-8 px-12 rounded-20 placeholder:text-[42px] text-[42px] w-full mt-16  outline outline-4 outline-blue-60"
        placeholder=""
        value={controlCode}
        onChange={onChange}
      />
      <div className="w-full flex justify-center">
        <button
          onClick={goNext}
          disabled={!controlCode}
          className="text-subHead-01 flex items-center disabled:bg-blue-20 bg-blue-100 active:bg-blue-150 text-white py-6 px-28 rounded-100 font-semibold my-20 shadow-default"
        >
          로그인
        </button>
      </div>
      <QwertyKeypad setInput={setControlCode} containerStyle="mt-4" />
    </main>
  );
}

export default Login;
