import { atom } from "jotai";

import { UserInfo } from "api";
import EmissionSelection from "../pages/EmissionSelection";

export type Language = "jp" | "ch" | "en" | "ko";
export type ModalInfo = {
  icon?: "ALERT" | "CHECK" | "ERROR" | "RESET";
  title?: string;
  description?: string;
  pointColorText?: string;
  btnText?: string; //(default: "확인")
  btnClass?: string;
  btnCallback?: () => void; //모달 닫기 전에 실행할 함수
  preventDefault?: boolean; //기본 닫기 동작 막기 (default: false)
  imageUrl?: string;
  subBtnText?: string;
  subBtnClass?: string;
  subBtnCallback?: () => void;
  subBtnPreventDefault?: boolean; //기본 닫기 동작 막기 (default: false)
  btnDisabled?: boolean;
  videoUrl?: string;
};
export type KioskStatus = {
  hp1TotalCount: number;
  bd1TotalCount: number;
  bd2TotalCount: number;
  hp1Error: boolean;
  bd1Error: boolean;
  bd2Error: boolean;
  bd1ErrorCode: number;
  bd2ErrorCode: number;
};
export type TgStatus = {
  controlCode: string;
  type: string;
  managerCode: string;
  bd1TotalCount: number;
  bd2TotalCount: number;
  hp1TotalCount: number;
  bd1Error: boolean;
  bd2Error: boolean;
  hp1Error: boolean;
  bd1ErrorCode: number;
  bd2ErrorCode: number;
  bd1Count: number; 
  bd2Count: number; 
  theGreetRefundId: number; 
  replaceWithdrawal: boolean;
};
export type CountInfo = {
  hp1Count: number;
  bd1Count: number;
  bd2Count: number;
};

export type RefundInfo = {
  refundIndexList: number[];
  hp1Count: number;
  bd1Count: number;
  bd2Count: number;
};

export type DepositInfo = {
  theGreetRefundIds?: number[];
  hp1Count: number;
  bd1Count: number;
  bd2Count: number;
};

export type DepositCountInfo = {
  hp1Count: number;
  bd1Count: number;
  bd2Count: number;
};

export type EmissionKiostStatus = {
  hp1Status: boolean;
  bd1Status: boolean;
  bd2Status: boolean;
};

export type AdminLoginInfo = {
  isManager: boolean | null;
  controlCode: string | null;
};

export type EmissionSelectionType = {
  isTaxRefund: boolean;
};

const getStorageValue = <T>(key: string, defaultValue: T) => {
  const storageValue = localStorage.getItem(key);
  return storageValue ? JSON.parse(storageValue) : defaultValue;
};

//읽기 전용
const getLanguageAtom = atom(getStorageValue("language", undefined));
const getLoginInfoAtom = atom(getStorageValue("loginInfo", undefined));
const getKioskStatusAtom = atom(
  getStorageValue("kioskStatus", {
    hp1TotalCount: 0,
    bd1TotalCount: 0,
    bd2TotalCount: 0,
    hp1Error: false,
    bd1Error: false,
    bd2Error: false,
    bd1ErrorCode: null,
    bd2ErrorCode: null,
  })
);
const getMoneyNotWithdrawn = atom(
  getStorageValue("moneyNotWithdrawn", {
    hp1Count: 0,
    bd1Count: 0,
    bd2count: 0,
  })
);
const getRefundInfoAtom = atom(getStorageValue("refundInfo", undefined));
const getDepositInfoAtom = atom(getStorageValue("depositInfo", undefined));
const getAdminLoginInfoAtom = atom(
  getStorageValue("adminLoginInfo", { isManager: null, controlCode: null })
);
const getEmissionSelection = atom(
  getStorageValue("emissionSelection", {
    isTaxRefund: false,
  })
);

const getEmissionKioskStatus = atom(
  getStorageValue("emissionKioskStatus", {
    bd1Status: true,
    bd2Status: false,
    hp1Status: false,
  })
);

// 읽기 쓰기 전용 (여권 정보)
const getPassportInfoAtom = atom(getStorageValue("passportInfo", undefined));
export const passportNumberAtom = atom(
  (get) => get(getPassportInfoAtom),
  (get, set, newPassportInfo: string | undefined) => {
    set(getPassportInfoAtom, newPassportInfo);
    localStorage.setItem("passportInfo", JSON.stringify(newPassportInfo));
  }
);

//읽기 쓰기 전용
export const modalInfoAtom = atom<null | ModalInfo>(null); //스토리지 저장 안함
export const languageAtom = atom(
  (get) => get(getLanguageAtom),
  (get, set, newLanguage: Language | undefined) => {
    set(getLanguageAtom, newLanguage);
    localStorage.setItem("language", JSON.stringify(newLanguage));
  }
);

export const loginInfoAtom = atom(
  (get) => get(getLoginInfoAtom),
  (get, set, newLoginInfo: UserInfo | undefined) => {
    set(getLoginInfoAtom, newLoginInfo);
    localStorage.setItem("loginInfo", JSON.stringify(newLoginInfo));
  }
);

export const kioskStatusAtom = atom(
  (get) => get(getKioskStatusAtom),
  (get, set, newKioskStatus: KioskStatus) => {
    set(getKioskStatusAtom, newKioskStatus);
    localStorage.setItem("kioskStatus", JSON.stringify(newKioskStatus));
  }
);
export const moneyNotWithdrawnAtom = atom(
  (get) => get(getMoneyNotWithdrawn),
  (get, set, newMoneyNotWithdrawn: CountInfo) => {
    set(getMoneyNotWithdrawn, newMoneyNotWithdrawn);
    localStorage.setItem(
      "moneyNotWithdrawn",
      JSON.stringify(newMoneyNotWithdrawn)
    );
  }
);
export const refundInfoAtom = atom(
  (get) => get(getRefundInfoAtom),
  (get, set, newRefundInfo: RefundInfo) => {
    set(getRefundInfoAtom, newRefundInfo);
    localStorage.setItem("refundInfo", JSON.stringify(newRefundInfo));
  }
);
export const depositInfoAtom = atom(
  (get) => get(getDepositInfoAtom),
  (get, set, newDepositInfo: DepositInfo) => {
    set(getDepositInfoAtom, newDepositInfo);
    localStorage.setItem("depositInfo", JSON.stringify(newDepositInfo));
  }
);
export const adminLoginInfoAtom = atom(
  (get) => get(getAdminLoginInfoAtom),
  (get, set, newAdminLoginInfo: AdminLoginInfo) => {
    set(getAdminLoginInfoAtom, newAdminLoginInfo);
    localStorage.setItem("adminLoginInfo", JSON.stringify(newAdminLoginInfo));
  }
);
export const emissionSelectionAtom = atom(
  (get) => get(getEmissionSelection),
  (get, set, newEmissionSelection: EmissionSelectionType) => {
    set(getEmissionSelection, newEmissionSelection);
    localStorage.setItem(
      "emissionSelection",
      JSON.stringify(newEmissionSelection)
    );
  }
);
/**키오스크 방출기 상태 */
export const emissionKioskStatusAtom = atom(
  (get) => get(getEmissionKioskStatus),
  (get, set, newEmissionKioskStatus: EmissionKiostStatus) => {
    set(getEmissionKioskStatus, newEmissionKioskStatus);
    localStorage.setItem(
      "emissionKiostStatus",
      JSON.stringify(newEmissionKioskStatus)
    );
  }
);
