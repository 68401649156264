import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import useUpdateKioskStatus from "hooks/useUpdateKioskStatus";
import useCompleteRefunds from "hooks/useCompleteRefunds";
import useLogging from "hooks/useLogging";
import connectPort from "images/common/connect_port_guide.png";
import {
  adminLoginInfoAtom,
  CountInfo,
  depositInfoAtom,
  emissionKioskStatusAtom,
  emissionSelectionAtom,
  kioskStatusAtom,
  loginInfoAtom,
  modalInfoAtom,
  moneyNotWithdrawnAtom,
  refundInfoAtom,
  passportNumberAtom,
} from "store/globalStateAtom";
import { calculateBC, calculateFCC, convertErrorCode } from "utils";
import useTGUpdateKioskStatus from "./useTGUpdateKioskStatus";
import useInitialize from "./useInitialize";
import useCompleteDeposit from "./useCompleteDeposit";
import useUpdateNotWithdrawn from "./useUpdateNotWithdrawn";
import { depositCalculateCount } from "../utils/index";

function useSerialCommunication2() {
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const STX = 0xfe;

  const [, setModalInfo] = useAtom(modalInfoAtom);
  const [emissionKioskStatus, setEmissionKioskStatus] = useAtom(
    emissionKioskStatusAtom
  );
  const [depositInfo, setDepositInfo] = useAtom(depositInfoAtom);
  const [kioskStatus, setKioskStatus] = useAtom(kioskStatusAtom);
  const [loginInfo] = useAtom(loginInfoAtom);
  const [moneyNotWithdrawn, setMoneyNotWithdrawn] = useAtom(
    moneyNotWithdrawnAtom
  );
  const [passportInfo] = useAtom(passportNumberAtom);
  const [, setAdminLoginInfo] = useAtom(adminLoginInfoAtom);

  const [isLoading, setIsLoading] = useState(false);

  const bd1ErrorCountRef = useRef(0); // bd1Error 0일 경우 최초 에러, 1일 경우 이후
  const bd2ErrorCountRef = useRef(0); // bd2Error 0일 경우 최초 에러, 1일 경우 이후
  const currentKioskStatusRef = useRef(kioskStatus); // 키오스크 상태 (에러유무, 에러코드, 지폐잔량)
  const { mutate: TGUpdateKioskStatus } = useTGUpdateKioskStatus(); // 더그리트 키오스크 상태 업데이트
  const { mutate: logOnServer } = useLogging(); // 키오스크 로그 서버 전송
  const { mutate: completeDeposit } = useCompleteDeposit();
  const { mutate: updateNotWithdrawn } = useUpdateNotWithdrawn();
  const { port, openInitializationModal, sendCommandToSerialPort } =
    useInitialize(); // port 생성 열기 닫기

  // * 아래 useRef 사용 이유 *
  //- processValue 함수가 내부 함수여서 외부 상태들의 최신화된 값을 가져오지 못함
  //- 내부함수에서는 ref를 통해서만 최신값을 가져올 수 있어서 useRef 사용
  const currentMoneyNotWithdrawnRef = useRef(moneyNotWithdrawn); // 미환급금
  const currentEmissionKioskStatusRef = useRef(emissionKioskStatus); // 더그리트용 키오스크 방출기 상태
  const currentLoginInfoRef = useRef(loginInfo); // 로그인 정보
  const resetDefaultInquiryCountRef = useRef(0); // 재설정 횟수
  const emitDefaultInquiryCountRef = useRef(0); // 방출 횟수
  const depositInfoRef = useRef(depositInfo); // 보증금 정보
  const passportInfoRef = useRef(passportInfo); // 여권 정보
  const currentCMDRef = useRef<"RESET" | "EMIT" | null>(); // 커멘드 정보
  const currentLocationRef = useRef<string>(""); // 이전 페이지 URL
  const withdrawalTargetCountRef = useRef({
    // 방출 목표 수량
    bd1Count: 0,
    bd2Count: 0,
    hp1Count: 0,
  });
  const replaceWithdrawal = useRef(0); // 대체 방출 시 이전 방출 횟수
  const currentAllErrorMoneyWithdrawnRef = useRef({
    // 모두 고장났을 때 방출 횟수
    bd1Count: 0,
    bd2Count: 0,
    hp1Count: 0,
  });
  const continuousCountRef = useRef(0); // 연속으로 에러났는지 판별

  useEffect(() => {
    currentKioskStatusRef.current = kioskStatus;
  }, [kioskStatus]);

  useEffect(() => {
    currentEmissionKioskStatusRef.current = emissionKioskStatus;
  }, [emissionKioskStatus]);

  useEffect(() => {
    depositInfoRef.current = depositInfo;
  }, [depositInfo]);

  useEffect(() => {
    currentLoginInfoRef.current = loginInfo;
  }, [loginInfo]);

  useEffect(() => {
    currentMoneyNotWithdrawnRef.current = moneyNotWithdrawn;
  }, [moneyNotWithdrawn]);

  /** 키오스크 로그 서버로 전송하는 함수 */
  const log = (data: string) => {
    logOnServer({ controlCode: loginInfo?.controlCode, data });
  };

  const goRefundComplete = (bd1Count: number, bd2Count: number) => {
    console.log("보증금 완료: ", bd1Count, bd2Count);
    // 보증금 방출 완료
    completeDeposit({
      ...depositInfoRef.current,
      controlCode: currentLoginInfoRef.current.controlCode,
      theGreetRefundIds: depositInfoRef.current.theGreetRefundIds,
      bd1Count,
      bd2Count,
    });
  };

  /** 보증금 방출 */
  async function depositEmitBills(countInfo: CountInfo) {
    console.log("depositEmitBills 실행");
    setIsLoading(true);
    const {
      bd1ErrorCode,
      bd2ErrorCode,
      bd1Error,
      bd2Error,
      hp1Error,
      hp1TotalCount,
      bd1TotalCount,
      bd2TotalCount,
    } = currentKioskStatusRef.current;

    //방출 목표 수량 저장
    withdrawalTargetCountRef.current = countInfo;
    const { bd1Count, bd2Count } = countInfo;

    currentCMDRef.current = "EMIT";
    console.log("countInfo: ", countInfo);

    // bd1, bd2의 전제잔량이 방출금액보다 적은 경우
    if (bd1TotalCount < bd1Count || bd2TotalCount < bd2Count) {
      setIsLoading(false);
      setModalInfo({
        title: t("error_cash_title"),
        description: t("error_cash_desc"),
        btnText: t("modal_confirm"),
        icon: "CHECK",
      });
      return;
    }

    if (bd1Error && bd2Error) {
      // 두개의 방출기 모두 문제가 있을시에만 에러모달 띄움
      const isOnSettingPage = location.pathname === "/setting";
      setIsLoading(false);
      setModalInfo({
        title: `에러가 발생했습니다.${
          hp1Error
            ? ""
            : `(${convertErrorCode(bd1ErrorCode || bd2ErrorCode).code})`
        }`,
        description: isOnSettingPage
          ? "에러가 해결되지 않았습니다."
          : t("error_kiosk_desc"),
        btnText: isOnSettingPage ? "닫기" : "조치하기",
        btnCallback: () => {
          if (isOnSettingPage) {
            return;
          }
          navigate("/setting");
        },
        icon: "ERROR",
      });
      return;
    }

    const CMD = 0x12;
    const BC = calculateBC(CMD);
    const dataArray = [
      0x00,
      0x00,
      bd1Count,
      bd2Count,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
    ];

    const packetExcludingFcc = [STX, BC, CMD, ...dataArray];
    const fcc = calculateFCC(packetExcludingFcc);
    const commandPacket = new Uint8Array([...packetExcludingFcc, fcc]);
    try {
      await sendCommandToSerialPort(commandPacket); // 비동기로 호출하여 실행 완료를 기다림
    } catch (error) {
      console.error(error);
    }
  }

  /** 기본 상태 조회 or 에러 조회  */
  const fetchKioskStatus = async (type: "DEFAULT" | "ERROR" = "DEFAULT") => {
    currentCMDRef;
    const CMD = 0x11;
    const BC = calculateBC(CMD);

    // 데이터 필드 설정
    const dataArray = [
      type === "DEFAULT" ? 0b00000000 : 0b00001000,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
    ];

    // 프레임 체크 코드 계산
    const packetExcludingFcc = [STX, BC, CMD, ...dataArray];
    const fcc = calculateFCC(packetExcludingFcc);

    // 명령어 패킷 생성
    const commandBuffer = new Uint8Array([...packetExcludingFcc, fcc]);
    // 명령어 전송
    try {
      await sendCommandToSerialPort(commandBuffer);
    } catch (err) {
      //응답
      console.error(err);
    }
  };

  /** 응답 수신 - 기본 상태, 에러가 났을 경우 */
  const readResponse = async () => {
    if (!port) return;
    //@ts-ignore
    const reader = port.readable.getReader();
    log(`리더기 생성 완료: ${reader}`);

    let RecvComBuffer: Array<number> = [];
    let RS232_RXStep = 0;

    const processValue = (tmp: number) => {
      if (RS232_RXStep === 0) {
        if (tmp !== 0xfe) {
          if (tmp === 0x11) {
            //ACK
            log("ACK");
          } else if (tmp === 0xee) {
            //NAK
            log("NAK");
          } else {
            log(`시리얼 통신 에러:: 알수 없는 패킷 헤더 ${tmp}`);
          }
        } else {
          RecvComBuffer[RS232_RXStep++] = tmp;
        }
      } else if (RS232_RXStep === 1) {
        if (tmp !== 18) {
          RS232_RXStep = 0;
        } else {
          RecvComBuffer[RS232_RXStep++] = tmp;
        }
      } else {
        RecvComBuffer[RS232_RXStep++] = tmp;
        // 응답 값이 총 19개이기 떄문에 19개를 모두 RecvComBuffer에 추가해줄 경우 다음 스텝으로 넘어간다.
        if (RS232_RXStep === 19) {
          RS232_RXStep = 0;
          const chksum = calculateFCC(RecvComBuffer.slice(0, -1));
          if (chksum === RecvComBuffer[18] && RecvComBuffer[17] === 0x13) {
            // 들어온 FCC와 체크섬 비교 & 버전 체크
            const CMD = RecvComBuffer[2];
            switch (CMD) {
              case 0x13: {
                // 방출기 상태 조회
                console.log("방출기 기본 상태: ", RecvComBuffer);
                console.log("명령어: ", currentCMDRef.current);
                console.log(bd1ErrorCountRef.current, bd2ErrorCountRef.current);
                console.log('초기 continuousCountRef: ', continuousCountRef);

                const [statusArr1, statusArr2, ...countArr] =
                  RecvComBuffer.slice(3, -1).map((v, idx) => {
                    // idx가 2보다 작은 경우 각 요소를 이진수 문자열로 변환하고, 왼쪽에 0으로 채원진 8자리의 이진수 문자열로 변환
                    if (idx < 2) {
                      return v
                        .toString(2)
                        .padStart(8, "0")
                        .split("")
                        .map(Number);
                      // idx가 2이상인 경우 요소를 숫자로 변경
                    } else {
                      return +v;
                    }
                  });
                console.log(`statusArr1: ${statusArr1}`);
                console.log(`statusArr2: ${statusArr2}`);
                console.log(`countArr: ${countArr}`);

                // 방출유무: 1 = 방출중, 2 = 방출 종료
                const isEmitting = (statusArr1 as number[])[4];
                console.log("방출 종료전:", isEmitting);
                // 방출중일 경우
                if (isEmitting) return;
                console.log("방출 종료:");

                // 방출이 종료되었을 경우 (방출 중이면 아래 로직은 실행되지 않음.)
                log(`응답 수신(0x13 기본 조회) : ${RecvComBuffer}`);
                // 방출기 에러
                const hp1Error = Boolean((statusArr1 as number[])[1]);
                const bd1Error = Boolean((statusArr2 as number[])[7]);
                const bd2Error = Boolean((statusArr2 as number[])[6]);
                const bd3Error = Boolean((statusArr2 as number[])[5]);
                console.log("bd1Error: ", bd1Error);
                console.log("bd2Error: ", bd2Error);

                /* 리셋(0x10) 요청시 자동 기본 조회(0x13) 응답 처리*/
                //- 리셋했을 때 에러 없으면: 자동 기본 조회 응답 1회만 옴
                //- 리셋했을 때 여전히 에러있으면: 자동 기본 조회 응답 2회 옴
                if (currentCMDRef.current === "RESET") {
                  resetDefaultInquiryCountRef.current++;
                  if (resetDefaultInquiryCountRef.current === 1) {
                    if (bd1Error || bd2Error) {
                      //리셋시 에러 있으면 2회 기본 조회할 경우,
                      //첫번째 조회 응답은 부정확하게 와서 break로 나가기(2번째때 처리)
                      log(
                        "리셋시 에러 존재(2회 기본 조회 응답 중 1회) - break"
                      );
                      break;
                    } else {
                      //리셋시 에러 없어서 기본 조회 1회만 하고 끝남. 밑에서 상태 업데이트
                      resetDefaultInquiryCountRef.current = 0;
                      bd1ErrorCountRef.current = 0;
                      bd2ErrorCountRef.current = 0;
                      replaceWithdrawal.current = 0;
                      continuousCountRef.current = 0;

                      setEmissionKioskStatus({
                        hp1Status: false,
                        bd1Status: true,
                        bd2Status: false,
                      });
                      currentEmissionKioskStatusRef.current = {
                        hp1Status: false,
                        bd1Status: true,
                        bd2Status: false,
                      };

                      log(
                        "리셋시 에러 없음(1회 기본 조회 응답 중 1회) - 응답 처리"
                      );
                    }
                  } else {
                    //기본 조회 2회 진행 중
                    resetDefaultInquiryCountRef.current = 0;
                    bd1ErrorCountRef.current = 0;
                    bd2ErrorCountRef.current = 0;
                    continuousCountRef.current = 0;
                    replaceWithdrawal.current = 0;
                    log(
                      "리셋시 에러 존재(2회 기본 조회 응답 중 2회) - 응답 처리"
                    );
                  }
                }

                console.log("실행");
                /* 방출(0x12) 요청시 자동 기본 조회(0x13) 응답 처리*/
                // - 방출 성공시: 자동 기본 조회 응답 1회만 옴
                // - 방출 실패시: 자동 기본 조회 응답 2회옴
                // 방출 성공, 실패유무에 상관없이 방출이 종료되었을 경우 2회 조회함.
                if (currentCMDRef.current === "EMIT") {
                  emitDefaultInquiryCountRef.current++;
                  log("방출(기본 조회 응답 1회) - 응답 처리");
                  if (emitDefaultInquiryCountRef.current === 2) {
                    //성공 방출 두번째 자동 기본 조회시 더 차감해서 업데이트 하지 않도록 break로 나가기
                    log(
                      "방출시 에러 없음(2회 기본 조회 응답 중 2회) - 초기화 + break"
                    );
                    emitDefaultInquiryCountRef.current = 0;
                    currentCMDRef.current = null;
                    break;
                  }
                }

                // 방출기 상태 (bd1, bd2만 동작-더그리트)
                // bd1, bd2 교차 방출
                const { bd1Status, bd2Status, hp1Status } =
                  currentEmissionKioskStatusRef.current;
                // 실제 방출 수량
                const depositBd1Count = countArr[8] as number;
                const depositBd2Count = countArr[9] as number;

                console.log("bd1Error: ", bd1Error);
                console.log("bd2Error: ", bd2Error);

                // bd1Status와 bd2Status를 설정하기 위한 기본값 설정
                let newBd1Status = bd1Status;
                let newBd2Status = bd2Status;

                // bd1Error와 bd2Error에 따라 새로운 상태 값 설정
                if (bd1Error) {
                  newBd1Status = false;
                  newBd2Status = true;
                  currentAllErrorMoneyWithdrawnRef.current = {
                    ...currentAllErrorMoneyWithdrawnRef.current,
                    bd1Count: depositBd1Count,
                    hp1Count: 0,
                  };
                  fetchKioskStatus("ERROR");
                }

                if (bd2Error) {
                  newBd1Status = true;
                  newBd2Status = false;
                  currentAllErrorMoneyWithdrawnRef.current = {
                    ...currentAllErrorMoneyWithdrawnRef.current,
                    bd2Count: depositBd2Count,
                    hp1Count: 0,
                  };
                  fetchKioskStatus("ERROR");
                }

                // 모든 방출기가 정상인 경우, 현재 상태의 반대값으로 설정
                if (!bd1Error && !bd2Error) {
                  newBd1Status = !bd1Status;
                  newBd2Status = !bd2Status;
                }

                if (currentCMDRef.current === "EMIT") {
                  //실제 방출 수량 비교후 덜뽑힌 금액 전역 상태로 저장
                  const newMoneyWithDrawn = {
                    hp1Count: withdrawalTargetCountRef.current.hp1Count,
                    bd1Count:
                      withdrawalTargetCountRef.current.bd1Count -
                      depositBd1Count,
                    bd2Count:
                      withdrawalTargetCountRef.current.bd2Count -
                      depositBd2Count,
                  };
                  setMoneyNotWithdrawn(newMoneyWithDrawn);
                  currentMoneyNotWithdrawnRef.current = newMoneyWithDrawn;
                  log(`미방출 수량: ${JSON.stringify(newMoneyWithDrawn)}`);
                }

                if (bd1Error && bd2Error) {
                  console.log("방출기 모두 에러");
                  continuousCountRef.current++;
                  console.log(
                    "continuousCountRef:",
                    continuousCountRef.current
                  );
                  fetchKioskStatus("ERROR");
                  break;
                }
                console.log("중간실행");

                if (bd1Error && !bd2Error && bd1ErrorCountRef.current === 0) {
                  continuousCountRef.current++;
                  console.log(
                    "bd2로 대체 방출 continuousCountRef:",
                    continuousCountRef
                  );
                  // log('BD1 에러로 인한 BD2 방출 진행');
                  console.log(
                    "bd1ErrorCountRef 변경전: ",
                    bd1ErrorCountRef.current
                  );
                  console.log("BD1 방출 중 에러 발생");

                  newBd1Status = false;
                  newBd2Status = true;

                  console.log(
                    "BD1 에러로 인한 BD2 방출 진행: ",
                    currentMoneyNotWithdrawnRef.current
                  );
                  // 시리얼 통신중일 경우 포트가 닫혀있는데 바로 실행해야 하는 경우
                  // 포트를 다시 열고 명령어를 추가하고, 닫아줘야하기 때문에 0.5초 딜레이 넣음
                  setTimeout(() => {
                    depositEmitBills({
                      hp1Count: 0,
                      bd1Count: 0,
                      bd2Count: currentMoneyNotWithdrawnRef.current.bd1Count,
                    });
                  }, 500);
                  console.log("BD1 에러로 인한 BD2 방출 완료");
                  replaceWithdrawal.current = depositBd1Count;
                  bd1ErrorCountRef.current++;

                  // emissionKioskStatus 설정
                  setEmissionKioskStatus({
                    hp1Status,
                    bd1Status: newBd1Status,
                    bd2Status: newBd2Status,
                  });

                  currentEmissionKioskStatusRef.current = {
                    hp1Status,
                    bd1Status: newBd1Status,
                    bd2Status: newBd2Status,
                  };

                  console.log(
                    "bd1ErrorCountRef 변경후: ",
                    bd1ErrorCountRef.current
                  );
                  break;
                }

                if (!bd1Error && bd2Error && bd2ErrorCountRef.current === 0) {
                  continuousCountRef.current++;
                  console.log(
                    "bd1로 대체 방출 continuousCountRef:",
                    continuousCountRef
                  );
                  // log('BD2 에러로 인한 BD1 방출 진행');
                  console.log(
                    "bd2ErrorCountRef 변경전: ",
                    bd2ErrorCountRef.current
                  );
                  console.log("BD2 방출 중 에러 발생");

                  newBd1Status = false;
                  newBd2Status = true;

                  console.log(
                    "BD2 에러로 인한 BD1 방출 진행: ",
                    currentMoneyNotWithdrawnRef.current
                  );
                  // 시리얼 통신중일 경우 포트가 닫혀있는데 바로 실행해야 하는 경우
                  // 포트를 다시 열고 명령어를 추가하고, 닫아줘야하기 때문에 0.5초 딜레이 넣음
                  setTimeout(() => {
                    depositEmitBills({
                      hp1Count: 0,
                      bd1Count: currentMoneyNotWithdrawnRef.current.bd2Count,
                      bd2Count: 0,
                    });
                  }, 500);
                  console.log("BD2 에러로 인한 BD1 방출 완료");
                  replaceWithdrawal.current = depositBd2Count;
                  bd2ErrorCountRef.current++;

                  // emissionKioskStatus 설정
                  setEmissionKioskStatus({
                    hp1Status,
                    bd1Status: newBd1Status,
                    bd2Status: newBd2Status,
                  });

                  currentEmissionKioskStatusRef.current = {
                    hp1Status,
                    bd1Status: newBd1Status,
                    bd2Status: newBd2Status,
                  };
                  console.log(
                    "bd2ErrorCountRef 변경후: ",
                    bd2ErrorCountRef.current
                  );
                  break;
                }

                // emissionKioskStatus 설정
                setEmissionKioskStatus({
                  hp1Status,
                  bd1Status: newBd1Status,
                  bd2Status: newBd2Status,
                });

                currentEmissionKioskStatusRef.current = {
                  hp1Status,
                  bd1Status: newBd1Status,
                  bd2Status: newBd2Status,
                };

                // 에러가 나지 않았을 경우 상태 업데이트
                const newKioskStatus =
                  currentCMDRef.current === "EMIT"
                    ? {
                        ...currentKioskStatusRef.current,
                        bd1TotalCount:
                          currentKioskStatusRef.current.bd1TotalCount -
                          depositBd1Count,
                        bd2TotalCount:
                          currentKioskStatusRef.current.bd2TotalCount -
                          depositBd2Count,
                        hp1Error,
                        bd1Error,
                        bd2Error,
                        bd3Error,
                      }
                    : {
                        //* 리셋일 경우: 에러 여부와 에러 여부가 0이면 에러 코드 0으로 업데이트
                        ...currentKioskStatusRef.current,
                        hp1Error,
                        bd1Error,
                        bd2Error,
                        bd3Error,
                        bd1ErrorCode: bd1Error
                          ? currentKioskStatusRef.current.bd1ErrorCode
                          : 0,
                        bd2ErrorCode: bd2Error
                          ? currentKioskStatusRef.current.bd2ErrorCode
                          : 0,
                        bd3ErrorCode: bd3Error
                          ? currentKioskStatusRef.current.bd3ErrorCode
                          : 0,
                      };

                setKioskStatus(newKioskStatus);
                currentKioskStatusRef.current = newKioskStatus;
                console.log("newKioskStatus: ", newKioskStatus);

                /** 키오스크 상태 업데이트 종류
                 * 1. 2개 모듈 정상 작동
                 * 2. 1개 모듈 고장 -> 대체 방출
                 * 3. 2개 모듈 고장
                 */
                if (currentCMDRef.current === "RESET") {
                  setIsLoading(false);
                  TGUpdateKioskStatus({
                    type: "WITHDRAWAL",
                    controlCode: currentLoginInfoRef.current.controlCode,
                    ...newKioskStatus,
                  });
                  break;
                }

                console.log(
                  "업데이트 시작: ",
                  bd1ErrorCountRef,
                  bd2ErrorCountRef
                );

                // 정상일 경우 bd1ErrorCountRef.current === 0 && bd2ErrorCountRef.current === 0)
                // BD2 대체 방출 bd1ErrorCountRef.current === 2 && bd2ErrorCountRef.current === 0
                // BD1 대체 방출 bd2ErrorCountRef.current === 2 && bd1ErrorCountRef.current === 0
                console.log("작동: ", bd1ErrorCountRef, bd2ErrorCountRef);
                if (currentCMDRef.current === "EMIT") {
                  if (
                    (bd1ErrorCountRef.current === 0 &&
                      bd2ErrorCountRef.current === 0) ||
                    (bd1ErrorCountRef.current === 2 &&
                      bd2ErrorCountRef.current === 0) ||
                    (bd1ErrorCountRef.current === 0 &&
                      bd2ErrorCountRef.current === 2)
                  ) {
                    console.log("정상 작동: ");
                    // 더그리트 키오스크 상태 업데이트
                    TGUpdateKioskStatus({
                      type: "WITHDRAWAL",
                      controlCode: currentLoginInfoRef.current.controlCode,
                      theGreetRefundId:
                        depositInfoRef.current.theGreetRefundIds[0],
                      replaceWithdrawal: false,
                      bd1Count: depositBd1Count,
                      bd2Count: depositBd2Count,
                      ...newKioskStatus,
                    });
                    setIsLoading(false);
                    setAdminLoginInfo({
                      isManager: null,
                      controlCode: null,
                    });

                    // 에러난 상태에서 정상방출 진행할 때 2회 호출하는 것을 막기 위함.
                    if (
                      bd1ErrorCountRef.current === 2 ||
                      bd2ErrorCountRef.current === 2
                    ) {
                      currentCMDRef.current = null;
                    }

                    log("환급액 방출 성공:: 환급 완료 페이지로 이동");
                    setTimeout(() => {
                      goRefundComplete(depositBd1Count, depositBd2Count);
                    }, 500);
                    break;
                  }

                  if (bd1ErrorCountRef.current === 1) {
                    console.log("BD2 대체방출: ");
                    TGUpdateKioskStatus({
                      type: "WITHDRAWAL",
                      controlCode: currentLoginInfoRef.current.controlCode,
                      theGreetRefundId:
                        depositInfoRef.current.theGreetRefundIds[0],
                      replaceWithdrawal: true,
                      bd1Count: replaceWithdrawal.current,
                      bd2Count: depositBd2Count,
                      ...newKioskStatus,
                    });
                    bd1ErrorCountRef.current++;
                    setIsLoading(false);
                    setAdminLoginInfo({
                      isManager: null,
                      controlCode: null,
                    });
                    log(
                      "BD1 에러로 인한 BD2 환급액 방출 성공:: 환급 완료 페이지로 이동"
                    );
                    setTimeout(() => {
                      goRefundComplete(
                        replaceWithdrawal.current,
                        depositBd2Count
                      );
                    }, 500);
                    currentCMDRef.current = null;
                    break;
                  }

                  if (bd2ErrorCountRef.current === 1) {
                    console.log("BD1 대체방출: ");
                    TGUpdateKioskStatus({
                      type: "WITHDRAWAL",
                      controlCode: currentLoginInfoRef.current.controlCode,
                      theGreetRefundId:
                        depositInfoRef.current.theGreetRefundIds[0],
                      replaceWithdrawal: true,
                      bd1Count: depositBd1Count,
                      bd2Count: replaceWithdrawal.current,
                      ...newKioskStatus,
                    });
                    bd2ErrorCountRef.current++;
                    setIsLoading(false);
                    setAdminLoginInfo({
                      isManager: null,
                      controlCode: null,
                    });
                    log(
                      "BD2 에러로 인한 BD1 환급액 방출 성공:: 환급 완료 페이지로 이동"
                    );
                    setTimeout(() => {
                      goRefundComplete(
                        depositBd1Count,
                        replaceWithdrawal.current
                      );
                    }, 500);
                    currentCMDRef.current = null;
                    break;
                  }
                }

                console.log("isEmitting: ", isEmitting);
                break;
              }
              case 0x19: {
                // 방출기 에러코드 응답
                console.log("방출기 에러 상태: ", RecvComBuffer);
                emitDefaultInquiryCountRef.current = 0;
                const [statusArr1, statusArr2, bd1ErrorCode, bd2ErrorCode] =
                  RecvComBuffer.slice(3, -1).map((v, i) => {
                    if (i < 2) {
                      return v
                        .toString(2)
                        .padStart(8, "0")
                        .split("")
                        .map(Number);
                    } else {
                      return +v;
                    }
                  });

                console.log("error code: ", bd1ErrorCode, bd2ErrorCode);
                console.log(
                  "대체 방출 중 모두 에러: ",
                  currentAllErrorMoneyWithdrawnRef
                );

                const newKioskStatus = {
                  ...currentKioskStatusRef.current,
                  bd1Error: Boolean((statusArr2 as number[])[7]),
                  bd2Error: Boolean((statusArr2 as number[])[6]),
                  hp1Error: Boolean((statusArr1 as number[])[1]),
                  bd1ErrorCode,
                  bd2ErrorCode,
                };

                // 미방출 금액
                const { bd1Count, bd2Count } =
                  currentMoneyNotWithdrawnRef.current;
                log(
                  `** 에러 발생 **\n- 응답 수신(0x19 에러 조회): ${RecvComBuffer}\n- 에러 정보: ${JSON.stringify(
                    newKioskStatus
                  )}`
                );
                setKioskStatus(newKioskStatus);
                currentKioskStatusRef.current = newKioskStatus;

                // 방출기 두개 모두 에러났을 경우 미방출 상태 업데이트
                if (
                  Boolean((statusArr2 as number[])[7]) &&
                  Boolean((statusArr2 as number[])[6])
                ) {
                  const handleUpdateNotWithdrawn = async (
                    newKioskStatus: any
                  ) => {
                    continuousCountRef.current++;
                    console.log(
                      "모두 에러 continuousCountRef:",
                      continuousCountRef
                    );
                    console.log(
                      "theGreetRefundIds: ",
                      depositInfoRef.current.theGreetRefundIds
                    );
                    await updateNotWithdrawn({
                      theGreetRefundId:
                        depositInfoRef.current.theGreetRefundIds[0],
                      notWithdrawCount: bd1Count ? bd1Count : bd2Count,
                    });
                    console.log(
                      "모두 에러: ",
                      depositInfoRef.current.theGreetRefundIds[0],
                      currentMoneyNotWithdrawnRef
                    );
                    console.log(
                      "모두 에러(BD1): ",
                      depositInfoRef.current.bd1Count,
                      moneyNotWithdrawn.bd1Count
                    );
                    console.log(
                      "모두 에러(BD2): ",
                      depositInfoRef.current.bd2Count,
                      moneyNotWithdrawn.bd2Count
                    );

                    // 마지막 에러 BD2
                    // 방출 된 총 금액
                    const currentTotalBd2ErrorDepositCount =
                      depositInfoRef.current.bd2Count -
                      currentMoneyNotWithdrawnRef.current.bd1Count;

                    // 처음에 B2에서 나간 값
                    const currentFirstBd2ErrorDepositCount =
                      currentTotalBd2ErrorDepositCount -
                      currentAllErrorMoneyWithdrawnRef.current.bd1Count;

                    console.log(
                      "마지막 에러 BD1: ",
                      currentTotalBd2ErrorDepositCount,
                      currentFirstBd2ErrorDepositCount
                    );

                    // 마지막 에런 BD1
                    const currentTotalBd1ErrorDepositCount =
                      depositInfoRef.current.bd1Count -
                      currentMoneyNotWithdrawnRef.current.bd2Count;
                    // 처음에 B1에서 나간 값
                    const currentFirstBd1ErrorDepositCount =
                      currentTotalBd1ErrorDepositCount -
                      currentAllErrorMoneyWithdrawnRef.current.bd2Count;

                    console.log(
                      "마지막 에러 BD2: ",
                      currentTotalBd1ErrorDepositCount,
                      currentFirstBd1ErrorDepositCount
                    );

                    // 나가야 하는 금액 = bd2Count = 8,
                    // 미방출 금액 = currentMoneyNotWithdrawnRef.current.bd1Count =  3,
                    // 방출 총 금액 = 나가야 하는 금액 - 미방출 금액 = 8 - 3 = 5

                    // 최근에 나간 금액 = currentAllErrorMoneyWithdrawnRef.current.bd1Count = 2

                    // 처음 방출된 금액 = 방출 총 금액 - 최근에 나간 금액 = 5 - 2 = 3
                    // updateNotWithdrawnMutation이 완료되고 나면 아래 코드가 실행됩니다.
                    console.log(
                      "continuousCountRef.current:",
                      continuousCountRef.current
                    );
                    console.log(
                      "currentKioskStatusRef: ",
                      currentKioskStatusRef,
                      currentFirstBd1ErrorDepositCount, // 3
                      currentAllErrorMoneyWithdrawnRef.current.bd1Count, // 0
                      currentFirstBd2ErrorDepositCount, // 0
                      currentAllErrorMoneyWithdrawnRef.current.bd2Count // 4
                    );
                    console.log(
                      "bd1TotalCount: ",
                      currentKioskStatusRef.current.bd1TotalCount,
                      currentFirstBd1ErrorDepositCount > 0
                        ? currentFirstBd1ErrorDepositCount
                        : currentAllErrorMoneyWithdrawnRef.current.bd1Count
                    );
                    console.log(
                      "bd2TotalCount: ",
                      currentKioskStatusRef.current.bd2TotalCount,
                      currentFirstBd2ErrorDepositCount > 0
                        ? currentFirstBd2ErrorDepositCount
                        : currentAllErrorMoneyWithdrawnRef.current.bd2Count
                    );
                    if (continuousCountRef.current === 3) {
                      TGUpdateKioskStatus({
                        type: "WITHDRAWAL",
                        controlCode: loginInfo.controlCode,
                        theGreetRefundId:
                          depositInfoRef.current.theGreetRefundIds[0],
                        replaceWithdrawal: false,
                        ...newKioskStatus,
                        bd1Count:
                          currentFirstBd1ErrorDepositCount > 0
                            ? currentFirstBd1ErrorDepositCount
                            : currentAllErrorMoneyWithdrawnRef.current.bd1Count,
                        bd2Count:
                          currentFirstBd2ErrorDepositCount > 0
                            ? currentFirstBd2ErrorDepositCount
                            : currentAllErrorMoneyWithdrawnRef.current.bd2Count,
                        bd1TotalCount:
                          currentKioskStatusRef.current.bd1TotalCount -
                            (currentFirstBd1ErrorDepositCount >
                          0
                            ? currentFirstBd1ErrorDepositCount
                            : currentAllErrorMoneyWithdrawnRef.current.bd1Count),
                        bd2TotalCount:
                          currentKioskStatusRef.current.bd2TotalCount -
                            (currentFirstBd2ErrorDepositCount >
                          0
                            ? currentFirstBd2ErrorDepositCount
                            : currentAllErrorMoneyWithdrawnRef.current.bd2Count),
                      });
                    } else {
                      console.log(
                        "bd1total: ",
                        currentKioskStatusRef.current.bd1TotalCount -
                          currentAllErrorMoneyWithdrawnRef.current.bd1Count
                      );
                      console.log(
                        "bd2total: ",
                        currentKioskStatusRef.current.bd2TotalCount -
                          currentAllErrorMoneyWithdrawnRef.current.bd2Count
                      );
                      TGUpdateKioskStatus({
                        type: "WITHDRAWAL",
                        controlCode: loginInfo.controlCode,
                        theGreetRefundId:
                          depositInfoRef.current.theGreetRefundIds[0],
                        replaceWithdrawal: false,
                        ...newKioskStatus,
                        bd1Count:
                          currentAllErrorMoneyWithdrawnRef.current.bd1Count,
                        bd2Count:
                          currentAllErrorMoneyWithdrawnRef.current.bd2Count,
                        bd1TotalCount:
                          currentKioskStatusRef.current.bd1TotalCount -
                          currentAllErrorMoneyWithdrawnRef.current.bd1Count,
                        bd2TotalCount:
                          currentKioskStatusRef.current.bd2TotalCount -
                          currentAllErrorMoneyWithdrawnRef.current.bd2Count,
                      });
                    }
                  };

                  handleUpdateNotWithdrawn(newKioskStatus);
                  setIsLoading(false);

                  const isOnSettingPage =
                    currentLocationRef.current === "/setting";
                  setModalInfo({
                    title: isOnSettingPage
                      ? "에러가 해결되지 않았습니다."
                      : `에러가 발생했습니다.${
                          currentKioskStatusRef.current.hp1Error
                            ? ""
                            : `(${
                                convertErrorCode(
                                  (bd1ErrorCode as number) ||
                                    (bd2ErrorCode as number)
                                ).code
                              })`
                        }`,
                    description: isOnSettingPage
                      ? "키오스크 에러 메뉴얼에 따라 조치해주세요.\n메뉴얼은 키오스크 내부에 부착되어 있습니다."
                      : t("error_kiosk_desc"),
                    btnText: isOnSettingPage ? "닫기" : "조치하기",
                    btnCallback: () => {
                      if (isOnSettingPage) {
                        return;
                      }
                      navigate("/setting");
                    },
                    icon: isOnSettingPage ? "ALERT" : "ERROR",
                  });
                }
                break;
              }
              default:
                log(`알 수 없는 CMD 에러 : ${CMD}`);
            }
          } else {
            // 수신 오류
            log("시리얼 통신 에러:: 유효하지 않은 형식의 응답 수신");
          }
        }
      }
    };

    try {
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          log("리더기 상태: DONE");
          await reader.releaseLock();
          break;
        }
        value.forEach(processValue);
      }
      return 0;
    } catch (err) {
      log(`시리얼 통신 에러:: 응답 수신 에러 ${err}`);
    }
  };

  /** 키오스크 BD1, BD2, HP1 리셋
   * 세팅 화면에서 새로고침시 사용되는 함수
   */
  const depositResetKiosk = async () => {
    // 리셋 시작
    setIsLoading(true);
    currentCMDRef.current = "RESET";
    // 패킷 정보
    const CMD = 0x10; // 제어
    const BC = calculateBC(CMD); // byte code

    // 데이터 필드 설정
    const dataArray = [
      0x00, 0b00000111, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
    ]; // 0b00000111 -> (6) : 방출기#2 리셋 방출기#1 리셋 호퍼 리셋

    // 프레임 체크 코드 계산
    const packetExcludingFcc = [STX, BC, CMD, ...dataArray];
    const fcc = calculateFCC(packetExcludingFcc);

    // 명령어 패킷 생성
    const commandBuffer = new Uint8Array([...packetExcludingFcc, fcc]);
    // 명령어 전송
    try {
      sendCommandToSerialPort(commandBuffer);
    } catch (err) {
      //응답
      setIsLoading(false);
      console.error(err);
    }
  };

  // 매번 페이지에서 작성하지 않아도 포트 연결이 되지 않았을 경우 되고있음.
  useEffect(() => {
    console.log("useSerialCommunication port", port);
    // 포트가 바로 안넘어옴
    if (port) {
      setModalInfo({
        title: "키오스크 연결이 완료되었습니다.",
        description: "",
        icon: "CHECK",
        btnCallback: () => {
          readResponse();
        },
      });
    } else {
      openInitializationModal();
    }
  }, [port]);

  return {
    depositResetKiosk,
    readResponse,
    depositEmitBills,
    depositIsLoading: isLoading,
  };
}

export default useSerialCommunication2;
