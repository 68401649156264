import { AxiosError } from "axios";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { updateNotWithdrawn, updateNotWithdrawnReqBody } from "api";
import {
  depositInfoAtom,
  kioskStatusAtom,
  loginInfoAtom,
  modalInfoAtom,
  moneyNotWithdrawnAtom,
} from "store/globalStateAtom";
import useTGUpdateKioskStatus from "./useTGUpdateKioskStatus";

function useUpdateNotWithdrawn() {
  const { t } = useTranslation();
  const { mutate: TGUpdateKioskStatus } = useTGUpdateKioskStatus();
  const [, setModalInfo] = useAtom(modalInfoAtom);
  const [kioskStatus] = useAtom(kioskStatusAtom);
  const [loginInfo] = useAtom(loginInfoAtom);
  const [depositInfo] = useAtom(depositInfoAtom);
  const [moneyNotWithdrawn] = useAtom(moneyNotWithdrawnAtom);

  return useMutation<number, AxiosError, updateNotWithdrawnReqBody>(
    (payload) => updateNotWithdrawn(payload),
    {
      retry: false,
      onSuccess: () => {
        console.log("🔆 Successfully saved kiosk status on the server...");
        console.log("notWithdrawn kioskStatus: ", kioskStatus);
      },
      onError: (err) => {
        if (err.message === "Network Error") {
          setModalInfo({
            title: t("error_network_title"),
            description: t("error_network_desc"),
            icon: "ALERT",
          });
        } else {
          setModalInfo({
            title: "에러가 발생했습니다.",
            description: t("error_default_desc"),
            icon: "ERROR",
          });
        }
      },
    }
  );
}

export default useUpdateNotWithdrawn;
