import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { comma } from 'utils';

type Props = {
  refundList: Array<{
    franchiseeName: string;
    refundIndex: number;
    createdDate: string;
    price: string;
    refund: string;
  }>;
};
function RefundListTable({ refundList }: Props) {
  const { t } = useTranslation();

  return (
    <table className="rounded-20 overflow-hidden w-full">
      <thead className="bg-mono-20 table w-full">
        <tr>
          <th>{t('payment_date')}</th>
          <th>{t('payment_place')}</th>
          <th>{t('payment_price')}</th>
          <th>{t('deposit_price')}</th>
        </tr>
      </thead>
      <tbody
        className={`block max-h-[420px] ${
          refundList?.length < 6 ? '' : 'overflow-y-scroll'
        }`}
      >
        {refundList?.map((v, i) => (
          <tr key={i} className="odd:bg-mono-01 even:bg-mono-05 w-full table">
            <td>{format(new Date(v.createdDate), 'yyyy.MM.dd')}</td>
            <td className="text-mono-65">{v.franchiseeName}</td>
            <td>{comma(v.price)}</td>
            <td className="font-semibold">{comma(v.refund)}</td>
          </tr>
        ))}
        {refundList?.length < 6 &&
          Array(6 - refundList?.length)
            .fill(null)
            .map((v, i) => (
              <tr
                key={i}
                className="odd:bg-mono-01 even:bg-mono-05 w-full table"
              >
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            ))}
      </tbody>
    </table>
  );
}

export default RefundListTable;
