
import { useState, useEffect } from 'react';
import { useAtom } from 'jotai';

import useLogging from './useLogging';
import { loginInfoAtom, modalInfoAtom } from 'store/globalStateAtom';
import connectPort from 'images/common/connect_port_guide.png';

function useInitialize() {
    const [port, setPort] = useState();
    const [loginInfo] = useAtom(loginInfoAtom);
    const [, setModalInfo] = useAtom(modalInfoAtom);

    // 키오스크 로그 서버 전송
    const { mutate: logOnServer } = useLogging();

    const log = (data: string) => {
        logOnServer({ controlCode: loginInfo?.controlCode, data });
    };

    const openInitializationModal = () => {
        const isFirstSerialConnection = getIsFistSerialConnection();
        console.log('isFirstSerialConnection: ', isFirstSerialConnection)
        console.log('port: ', port)
        setModalInfo({
            title: '키오스크 기기 연결을 허용해주세요.',
            pointColorText: isFirstSerialConnection
                ? undefined
                : 'スタッフでお問い合わせください。请咨询职员。Please ask for assistance. ',
            description: `${isFirstSerialConnection
                ? '키오스크를 사용하기 위해 연결이 필요합니다.'
                : '페이지 새로고침이 감지되어 키오스크 연결이 끊어졌습니다.'
                }\n아래 사진을 참고해 연결을 진행해주세요.`,
            btnText: '확인',
            icon: 'CHECK',
            imageUrl: connectPort,
            btnCallback: initialize,
        });
    };

    const getIsFistSerialConnection = () => {
        const isFirstSerialConnection = localStorage.getItem(
            'isFirstSerialConnection'
        );
        if (!isFirstSerialConnection) {
            localStorage.setItem('isFirstSerialConnection', 'NO');
            return true;
        } else {
            return false;
        }
    };

    /** 포트 연결 및 열기 */
    const initialize = async () => {
        try {
            //@ts-ignore
            const port = await navigator.serial.requestPort();
            log('포트 연결 성공');
            setPort(port);
            await port.open({ baudRate: 9600 });
            log('포트 열기 성공');
        } catch (err) {
            setModalInfo({
                title: '웹 사이트 연결을 확인해주세요.',
                description: `이미 키오스크 연결이 완료된 페이지가 실행 중입니다.\n현재 페이지를 닫고 이전에 실행 중인 페이지를 사용해주세요.`,
                btnText: '닫기',
                preventDefault: true,
                btnCallback: openInitializationModal,
                icon: 'ALERT',
            });
            log('포트 열기 실패');
        }
    };

    /** 키오스크로 명령어 전송 (PC -> IF)  */
    async function sendCommandToSerialPort(commandBuffer: Uint8Array) {
        log(`요청 전송: ${commandBuffer}`);

        //@ts-ignore
        const writer = await port.writable.getWriter();
        await writer.write(commandBuffer);
        await writer.releaseLock();
    }

    return {
        port,
        openInitializationModal, sendCommandToSerialPort
    }
}

export default useInitialize