import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { RefundInfo } from 'api';
import { useAtom } from 'jotai';

import RefundListTable from 'components/Refund/RefundListTable';
import RefundSummaryTable from 'components/Refund/RefundSummaryTable';
import Layout from 'components/common/Layout';
import progressKo from 'images/Refund/progress_ko_deposit_2.png';
import progressCh from 'images/Refund/progress_ch_deposit_2.png';
import progressEn from 'images/Refund/progress_en_deposit_2.png';
import progressJp from 'images/Refund/progress_jp_deposit_2.png';
import arrowLeft from 'images/common/chevron_left_black.png';
import withdraw from 'images/Refund/withdraw.png';
import { CountInfo, languageAtom } from 'store/globalStateAtom';
import { calculateCount, comma } from 'utils';

type Props = {
  isLoading: boolean;
  emitBills: (countInfo: CountInfo) => void;
};
function Refund({ emitBills, isLoading }: Props) {
  const { t } = useTranslation();
  const locationState = useLocation().state;
  const data = locationState?.data;
  const [lang] = useAtom(languageAtom);
  const { refundList, ...summaryData } = data || ({ refundList: null } as any);
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/passport-certification');
  };

  const goNext = () => {
    // isTaxRefund === false 일 때, BD1, BD2만 사용한다.
    // 1. 이전 사람이 BD1에서 방출했다면 다음 사람은 BD2에서 방출한다(반복)
    // 2. 총 4장이 방출해야 할 때, BD1에서 3번째에서 에러가 날 경우 나머지 2장은 BD2에서 방출하고 BD1에서 방출되지 않도록 한다.
    // 3. BD1, BD2가 모두 에러났을 경우 Error Modal을 렌더링한다.
    // 4. 총 4장을 출력해야할 때 BD1 = 2장, BD2 = 4장 일때,
    // 4-1. BD1에서 방출해야하는 경우 BD1에서 2장, BD2에서 2장을 방출한다?
    // 4-2. BD1에서 방출해야하는 경우 BD1에 지폐가 모자르기 때문에 BD2에서 4장을 방출한다?
    emitBills(calculateCount(data.totalRefund));
  };

  return (
    <Layout isSetting isLoading={isLoading}>
      <img
        src={
          lang === 'en'
            ? progressEn
            : lang === 'jp'
            ? progressJp
            : lang === 'ch'
            ? progressCh
            : progressKo
        }
        height={90}
        width={960}
        alt="progress"
        className="mb-16 w-full"
      />
      <RefundListTable refundList={refundList} />
      <h2 className="text-head-01 font-bold text-mono-80 mt-16 mb-12">
        {t('collect_deposit')}
      </h2>
      {summaryData && <RefundSummaryTable {...summaryData} />}
      <div className="flex justify-between">
        <button
          onClick={goBack}
          className="text-subHead-01 flex items-center bg-white py-6 px-12 rounded-100 text-mono-80 font-semibold mt-36 shadow-default"
        >
          <img src={arrowLeft} width={36} height={36} alt="arrow_left" />
          {t('prev_step')}
        </button>
        <button
          onClick={goNext}
          className="text-head-02 flex items-center disabled:bg-blue-20 bg-blue-100 text-white py-6 px-12 rounded-100 font-semibold mt-36 shadow-default active:bg-blue-150"
        >
          <img
            src={withdraw}
            width={40}
            height={40}
            alt="withdraw"
            className="mr-2"
          />
          {summaryData && (
            <>
              {lang === 'ko'
                ? `${comma(summaryData.totalRefund)}원 환급금 받기`
                : lang === 'jp'
                ? `KRW ${comma(summaryData.totalRefund)} 払い戻し金を受け取る`
                : lang === 'ch'
                ? `${comma(summaryData.totalRefund)} KRW 接收退税额`
                : `${comma(summaryData.totalRefund)} KRW Get Refund`}
            </>
          )}
        </button>
      </div>
    </Layout>
  );
}

export default Refund;
