import React from 'react';
import { useTranslation } from 'react-i18next';
import { comma } from 'utils';

type Props = {
  amount: string;
};
function DepositSummaryTable({ amount }: Props) {
  const { t } = useTranslation();

  return (
    <table className="rounded-20 overflow-hidden w-full">
      <thead className="bg-blue-10 table w-full">
        <tr>
          <th>{t('currency')}</th>
          <th className="bg-blue-20">{t('total_deposit')}</th>
        </tr>
      </thead>
      <tbody>
        <tr className="odd:bg-mono-01 even:bg-mono-05 w-full table">
          <td className="text-mono-65">KRW</td>
          <td className="text-blue-100 font-semibold">{comma(amount)}</td>
        </tr>
      </tbody>
    </table>
  );
}

export default DepositSummaryTable;
