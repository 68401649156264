import { AxiosError } from "axios";
import { useAtom } from "jotai";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";

import { postDepositInfo, PostDepositInfoReqBody, PostDepositInfo } from "api";
import { Error } from "api/config";
import {
  modalInfoAtom,
  depositInfoAtom,
  emissionKioskStatusAtom,
  CountInfo,
} from "store/globalStateAtom";
import { depositCalculateCount } from "utils";

type IProps = {
  depositEmitBills: (countInfo: CountInfo) => void;
};

function useApplyDepositInfo({ depositEmitBills }: IProps) {
  const { t } = useTranslation();
  const [, setDepositInfo] = useAtom(depositInfoAtom);
  const [, setModalInfo] = useAtom(modalInfoAtom);
  const [emissionKioskStatus, setEmissionKioskStatus] = useAtom(
    emissionKioskStatusAtom
  );

  return useMutation<
    PostDepositInfo,
    AxiosError<Error>,
    PostDepositInfoReqBody
  >((payload) => postDepositInfo(payload), {
    retry: false,
    onSuccess: (data) => {
      console.log("신청: ", data.theGreetRefundId);
      console.log("신청시: ", emissionKioskStatus);
      setDepositInfo({
        theGreetRefundIds: [data.theGreetRefundId],
        ...depositCalculateCount(data.response.amount, emissionKioskStatus),
      });

      if (data.notWithdrawn && data.response.amount) {
        depositEmitBills(
          depositCalculateCount(
            String(parseInt(data.response.amount) + data.notWithdrawn.amount),
            emissionKioskStatus
          )
        );
      } else {
        depositEmitBills(
          depositCalculateCount(data.response.amount, emissionKioskStatus)
        );
      }
    },
    onError: (err) => {
      const errCode = err.response?.data.code;
      console.log("errCode", errCode);
      switch (errCode) {
        //* 환급 가능 건이 없는 경우
        case "TG0001":
        case "TG0002":
          setModalInfo({
            title: t("error_deposit_title"),
            description: t("error_passport_desc"),
            icon: "ALERT",
          });
          break;
        default:
          if (err.message === "Network Error") {
            setModalInfo({
              title: t("error_network_title"),
              description: t("error_network_desc"),
              icon: "ALERT",
            });
          } else {
            setModalInfo({
              title: "에러가 발생했습니다.",
              description: t("error_default_desc"),
              icon: "ERROR",
            });
          }
          break;
      }
    },
  });
}

export default useApplyDepositInfo;
