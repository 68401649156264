import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import i18next from 'locale/i18n';
import { useAtom } from 'jotai';

import LanguageSelectionPage from 'pages/LanguageSelection';
import PromotionPage from 'pages/Promotion';
import PassportCertification from 'pages/PassportCertification';
import Refund from 'pages/Refund';
import Deposit from 'pages/Deposit';
import Login from 'pages/Login';
import RefundComplete from 'pages/RefundComplete';
import Setting from 'pages/Setting';
import CashCharging from 'pages/CashCharging';
import useSerialCommunication from 'hooks/useSerialCommunication';
import useSerialCommunication2 from 'hooks/useSerialCommunication2';
import { languageAtom } from 'store/globalStateAtom';
//@ts-ignore
import useCheckCurrentVersion from 'hooks/useCheckCurrentVersion';
import useBlockAccessIfNotLoggedIn from 'hooks/useBlockAccessIfNotLoggedIn';
import EmissionSelection from 'pages/EmissionSelection';

function AppRoutes() {
  useBlockAccessIfNotLoggedIn();
  const { emitBills, resetKiosk, isLoading } = useSerialCommunication();
  const { depositEmitBills, depositResetKiosk, depositIsLoading } = useSerialCommunication2();
  const [language] = useAtom(languageAtom);
  const { getCurrentVersion } = useCheckCurrentVersion();

  useEffect(() => {
    i18next.changeLanguage(language || 'ko');
  }, [language]);

  return (
    <>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/promotion' element={<PromotionPage />} />
        <Route path='/language-selection' element={<LanguageSelectionPage />} />
        <Route path='/emission-selection' element={<EmissionSelection />} />
        <Route
          path='/passport-certification'
          element={<PassportCertification />}
        />
        <Route
          path='/refund'
          element={<Refund emitBills={emitBills} isLoading={isLoading} />}
        />
        <Route
          path='/deposit'
          element={
            <Deposit depositEmitBills={depositEmitBills} isLoading={isLoading} />
          }
        />
        <Route path='/refund/complete' element={<RefundComplete />} />
        <Route
          path='/setting'
          element={<Setting resetKiosk={depositResetKiosk} isLoading={depositIsLoading} />}
        />
        <Route path='/setting/cash-charging' element={<CashCharging />} />
      </Routes>
      <div className='fixed bottom-4 text-center w-full text-mono-35 text-[14px]'>
        {'v' + getCurrentVersion()}
      </div>
    </>
  );
}

export default AppRoutes;
