import React from 'react';
import { useAtom } from 'jotai';
import { emissionSelectionAtom, languageAtom } from 'store/globalStateAtom';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

type Props = {
  isRefund?: boolean;
  imgUrl: string;
};
function BtnItem({ imgUrl, isRefund }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, setRefundInfo] = useAtom(emissionSelectionAtom);

  const onClick = () => {
    setRefundInfo({ isTaxRefund: isRefund ? true : false });
    navigate('/passport-certification');
  };

  return (
    <button
      onClick={onClick}
      className='flex-1 flex-col justify-center bg-white min-h-[518px] py-[113px] px-[90px] flex items-center rounded-20 shadow-default active:opacity-80 gap-5'
    >
      <img src={imgUrl} width={140} height={140} alt='flag' />
      <p className='text-mono-80 font-bold text-head-01 whitespace-pre-line'>
        {isRefund ? t('tax_refund_receive') : t('deposit_receive')}
      </p>
    </button>
  );
}

export default BtnItem;
